import Vue from 'vue'
import App from './App.vue'
import router from './router'
//import 'bootstrap'
//import 'bootstrap/dist/css/bootstrap.min.css'
//import VueResource from 'vue-resource';
//import io from 'socket.io-client';
import vuetify from './plugins/vuetify';
//import "vuetify/dist/vuetify.min.css"; 
//import VuetifyMoney from "vuetify-money";
//import VueMask from 'v-mask'
import mask from 'vuejs-mask'
//import VueSocialSharing from 'vue-social-sharing'
//import VueObserveVisibility from 'vue-observe-visibility'
import moment from 'moment';
//import VueMoment from 'vue-moment';

//import { BASE_URL_WEBSOCKET } from "./services/API"

Vue.config.productionTip = false

Vue.use(mask);
//Vue.use(VueSocialSharing);
//Vue.use(VueObserveVisibility)
//Vue.use(VuetifyMoney);

// Usado para viabilizar o moment nas aplicaoes sem tere de reconfigurar individualmente dentro de cada aplicacao
moment.locale('pt-br');
moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss.SSS-0300'; 
Vue.prototype.moment = moment;
//Vue.use(VueMoment, { moment });

// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

//------------------
// export const socket = io(BASE_URL_WEBSOCKET, {  transports          : ['websocket'],
//                                                 secure              : false,
//                                                 reconnection        : true,
//                                                 rejectUnauthorized  : false,
//                                              }
//                         );
// socket.on("connect_error", function(e){ 	
//     console.log("Erro WEBSocket: ", e);
//     socket.connect();
// });
// socket.on("connect", function(e) {
//   console.log("ON CONNECT 00000000000 : ", e);
//   socket.on("sala_mapa", 32);
// });
// socket.on("connection", function(e) {
//   console.log("ON CONNECTION 1111111111 : ", e);
//   socket.on("sala_mapa", 32);
// });
// socket.on("reconnect", function(e) {
//   console.log("ON RECONNECT 222222222222 : ", e);
// });

//------------------
// Desativa o foco no Elemento com a opção "v-blur", porém não pula para o proximo elemento
// Vue.directive('blur', {
//   inserted: function (el) {
//     el.onfocus = (ev) => ev.target.blur()
//   }
// });

//Vue.use(VueResource);
//Vue.use(require('vue-resource'));

new Vue({
  //store,
  router,
  vuetify,
  //VuetifyMoney,  
  render: h => h(App),

}).$mount('#app')
