import moment from 'moment'
import generatePdfThumbnails from 'pdf-thumbnails-generator';

String.prototype.initCap = function () {
	return this.toLowerCase().replace(/(?:^|\b)[a-z]/g, function (m) {
	   return m.toUpperCase();
	});
 };
 
const hexToBase64 = (str) => {
	return btoa(
		String.fromCharCode.apply(
			null,
			str
				.replace(/\r|\n/g, "")
				.replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
				.replace(/ +$/, "")
				.split(" ")
		)
	);
};

const removeMaskMoney = (value) => {
	var ls_aux
	if (value)
		ls_aux = value.replace(/./g, ",")
	//console.log("removeMaskMoney1", ls_aux)
	if (Number(ls_aux) || !ls_aux) {
		return value
	} else {
		ls_aux = value.replace(/[^0-9,',']/g, "")
		//console.log("removeMaskMoney2", ls_aux)
		return ls_aux
	}
};

const formatNumber = (number) => {
	if(!number) return null
	if (number || number === 0) {
		return number
			.toFixed(2)
			.replace(".", ",")
			.split("")
			.reverse()
			.map((v, i) => (i > 5 && (i + 6) % 3 === 0 ? `${v}.` : v))
			.reverse()
			.join("");
	} else {
		return number;
	}
};

const to_Number = (value) => {
	let val = value
	if (!val)
		val = '0';
	if (val && !(val/1)) {
		//console.log("value/11", val);
	  	val = val.replace('.', '').replace(',', '.')
	  	//console.log("value/22", val);
	}
	val = Number((val/1).toFixed(2));
	return val
};

const formatPrice = (value) => {
	if (!value) return null

	let val = (value/1).toFixed(2).replace('.', ',')
	return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
};

const formatDate = (value) => {
  if (value?.indexOf("[") > 0) {
		// Extrai as partes da data e hora do formato "YYYYMMDDHHMMSS"
		const year = value.substr(0, 4);
		const month = value.substr(4, 2);
		const day = value.substr(6, 2);
		const hours = value.substr(8, 2);
		const minutes = value.substr(10, 2);
		const seconds = value.substr(12, 2);

		// Extrai o fuso horário do formato "[offset:timezone]"
		const timeZoneStartIndex = value.indexOf("[") + 1;
		const timeZoneEndIndex = value.indexOf("]");
		const timeZone = value.substring(timeZoneStartIndex, timeZoneEndIndex);

		// Monta a data no formato "DD/MM/YYYY HH:MM:SS" com o fuso horário
		//const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${timeZone}`;
		value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	}
	return value ? moment(value).format('DD/MM/YYYY') : ''
};

const formatDateTime = (value) => {
	let value2 = null;
	if (value?.indexOf("[") > 0 || value?.length == 14) {
		  // Extrai as partes da data e hora do formato "YYYYMMDDHHMMSS"
		  const year = value.substr(0, 4);
		  const month = value.substr(4, 2);
		  const day = value.substr(6, 2);
		  const hours = value.substr(8, 2);
		  const minutes = value.substr(10, 2);
		  const seconds = value.substr(12, 2);
  
		  // Extrai o fuso horário do formato "[offset:timezone]"
		  const timeZoneStartIndex = value.indexOf("[") + 1;
		  const timeZoneEndIndex = value.indexOf("]");
		  const timeZone = value.substring(timeZoneStartIndex, timeZoneEndIndex);
  
		  // Monta a data no formato "DD/MM/YYYY HH:MM:SS" com o fuso horário
		  //const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${timeZone}`;
		  value2 = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		}
	return value2 ? moment(value2).format('DD/MM/YYYY HH:mm:ss') : ''
};

const emailIsValid = (email) => {
	if (!email)
		return false
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const telefoneIsValid = (telefone) => {
	if (!telefone)
		return false
	const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/
	const ls_limpo = (String(telefone).replace(new RegExp(defaultDelimiters, 'g'), ''));
	if ((ls_limpo.length <  11 && ls_limpo.charAt(2) != '9') ||
	    (ls_limpo.length >= 11 && ls_limpo.charAt(2) == '9'))
		return /^\(\d{2}\)\d{4,5}-\d{4}$/gi.test(telefone);
};

const maskCpfCnpj = (value) => {
	//value = value.replace(/\D/g, "");
	if (!value)
		return value

	if (value.length <= 14) {
		value = value.replace(/\D/g, "");
		//CPF
		value = value.replace(/(\d{3})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d)/, "$1.$2");
		value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
	} else {
		value = value.replace(/\D/g, "");
		//CNPJ
		value = value.replace(/^(\d{2})(\d)/, "$1.$2");
		value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
		value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
		value = value.replace(/(\d{4})(\d)/, "$1-$2");
	}
	return value;
};

const maskCPFCNPJ = (value) => {
	const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/
	const ls_limpo = (String(value).replace(new RegExp(defaultDelimiters, 'g'), ''));
	if (ls_limpo.length > 11)
		return "##.###.###/####-##"
	else
		return "###.###.###-##"
}

const maskTELEFONE = (value) => {
	const defaultDelimiters = /[-!$%^&*()_+|~=`{}[\]:";'<>?,./\\ ]/
	const ls_limpo = (String(value).replace(new RegExp(defaultDelimiters, 'g'), ''));
	if (ls_limpo.length <= 10)
		return "(##)####-####"
	else
		return "(##)#####-####"
}  

const maskCelular = (value) => {
	value = value.replace(/\D/g, "");
	value = value.replace(/(\d{2})(\d)/, "($1) $2");
	value = value.replace(/(\d{5})(\d)/, "$1 - $2");

	return value;
};

const CpfCnpjIsValid = (value) => {
	if (!value)
		return false

	if (value.length === 14) {
		var cpf = value.trim();

		cpf = cpf.replace(/\./g, "");
		cpf = cpf.replace("-", "");
		cpf = cpf.split("").map(Number);

		let v1 = 0;
		let v2 = 0;
		let aux = false;

		for (let i = 1; cpf.length > i; i++) {
			if (cpf[i - 1] !== cpf[i]) {
				aux = true;
			}
		}

		if (aux === false) {
			return false;
		}

		for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
			v1 += cpf[i] * p;
		}

		v1 = (v1 * 10) % 11;

		if (v1 === 10) {
			v1 = 0;
		}

		if (v1 !== cpf[9]) {
			return false;
		}
		for (let i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
			v2 += cpf[i] * p;
		}

		v2 = (v2 * 10) % 11;

		if (v2 === 10) {
			v2 = 0;
		}

		if (v2 !== cpf[10]) {
			return false;
		} else {
			return true;
		}
	} else if (value.length === 18) {
		var cnpj = value.trim();

		cnpj = cnpj.replace(/\./g, "");
		cnpj = cnpj.replace("-", "");
		cnpj = cnpj.replace("/", "");
		cnpj = cnpj.split("").map(Number);

		let v1 = 0;
		let v2 = 0;
		let aux = false;

		for (let i = 1; cnpj.length > i; i++) {
			if (cnpj[i - 1] !== cnpj[i]) {
				aux = true;
			}
		}

		if (aux === false) {
			return false;
		}

		for (let i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
			if (p1 >= 2) {
				v1 += cnpj[i] * p1;
			} else {
				v1 += cnpj[i] * p2;
			}
		}

		v1 = v1 % 11;

		if (v1 < 2) {
			v1 = 0;
		} else {
			v1 = 11 - v1;
		}

		if (v1 !== cnpj[12]) {
			return false;
		}

		for (let i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
			if (p1 >= 2) {
				v2 += cnpj[i] * p1;
			} else {
				v2 += cnpj[i] * p2;
			}
		}

		v2 = v2 % 11;

		if (v2 < 2) {
			v2 = 0;
		} else {
			v2 = 11 - v2;
		}

		if (v2 !== cnpj[13]) {
			return false;
		} else {
			return true;
		}
	} else {
		return false;
	}
};

const filterArrayByString = (mainArr, searchText) => {
	if (searchText === "") {
		return mainArr;
	}

	searchText = searchText.toLowerCase();

	return mainArr.filter((itemObj) => {
		return searchInObj(itemObj, searchText);
	});
};

const searchInObj = (itemObj, searchText) => {
	for (const prop in itemObj) {
		if (!itemObj.hasOwnProperty(prop)) {
			continue;
		}

		const value = itemObj[prop];

		if (typeof value === "string") {
			if (searchInString(value, searchText)) {
				return true;
			}
		} else if (Array.isArray(value)) {
			if (searchInArray(value, searchText)) {
				return true;
			}
		}

		if (typeof value === "object") {
			if (searchInObj(value, searchText)) {
				return true;
			}
		}
	}
};

const searchInArray = (arr, searchText) => {
	for (const value of arr) {
		if (typeof value === "string") {
			if (searchInString(value, searchText)) {
				return true;
			}
		}

		if (typeof value === "object") {
			if (searchInObj(value, searchText)) {
				return true;
			}
		}
	}
};

const searchInString = (value, searchText) => {
	return value.toLowerCase().includes(searchText);
};

// Verifica se existem objetos duplicados dentro do array atraves das chaves enviadas
const duplicateKeysInArray = (array, keys) => {
	if (Array.isArray(keys)) {
		if (keys.length == 1) {
			return array.find((item, index) => {
				return array.find((x, ind)=> (x[keys[0]] === item[keys[0]]) && index !== ind )
			})
		}
  	if (keys.length == 2) {
			return array.find((item, index) => {
				return array.find((x, ind)=> (x[keys[0]] === item[keys[0]] &&
																			x[keys[1]] === item[keys[1]] ) && index !== ind )
			})
		}
		if (keys.length == 3) {
			return array.find((item, index) => {
				return array.find((x, ind)=> (x[keys[0]] === item[keys[0]] &&
																			x[keys[1]] === item[keys[1]] &&
																			x[keys[2]] === item[keys[2]] ) && index !== ind )
			})
		}
	}
	else { 
		return array.find((item, index) => {
			return array.find((x, ind)=> (x[keys] === item[keys]) && index !== ind )
		})
	}
}

const calculaFinanciamento = (saldo, taxa, qtde) => {
	var numero2 = taxa / 100;
	var numero3 = 1 - Math.pow(1 / (1 + taxa / 100), qtde);

	if (taxa === 0)
		return roundToTwo(saldo / qtde);
	else
		return roundToTwo(saldo * (numero2 / numero3));
};

const roundToTwo = (num) => {
	return num.toFixed(2);
};

const formatDateToLocaleString = (str) => {
	if (!str) return "";
	let data = new Date(str);
	return data.toLocaleString();
};

const getRadDeg = (deg) => {
	var rad = deg / (Math.PI/180);
	return rad;
}

const handleFileUpload = (e) => {
	let reader = new FileReader();
	if (e.target.files && e.target.files.length > 0) {
		reader.readAsDataURL(e.target.files[0]);
		reader.onload = (file) => {
			return file.target.result;
		};
	}
	else
		return null;
};

const nomeIniciais = (str) => {
	if (!str)
	  return "...";
	let ls_Nome			= "";
	let ls_SobreNome	= "";
	ls_Nome      = str.substr(0, 1).toUpperCase();
	ls_SobreNome = str.substr(str.indexOf(" ")+1, 1).toUpperCase();
	return ls_Nome + ls_SobreNome;
}

const dataURItoBlob = (dataURI) => {
  const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
    ? atob(dataURI.split(',')[1])
    : unescape(dataURI.split(',')[1]);
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};

const resizeImage = ({ file, maxSize }) => {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  const resize = () => {
    let { width, height } = image;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);

    const dataUrl = canvas.toDataURL('image/jpeg');

    return dataURItoBlob(dataUrl);
  };

	if (file && !file.type)
    file = dataURItoBlob(file);

	return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'));
      return;
    }
    reader.onload = (readerEvent) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };

    reader.readAsDataURL(file);
  });
};

const blobToBase64 = blob => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	return new Promise(resolve => {
	  reader.onloadend = () => {
		resolve(reader.result);
	  };
	});
};

// Monta JSON agrupando por multiplas chaves (groups)
const groupArrayMultKeys = (array, groups) => {
	var grouped = {};

	array.forEach(function (a) {
		groups.reduce(function (o, g, i) {                            // take existing object,
			o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {}); // or generate new obj, or
			return o[a[g]];                                           // at last, then an array
		}, grouped).push(a);
	});

	return grouped;
};

const sortBy = (function () {
	var toString = Object.prototype.toString,
		// default parser function
		parse = function (x) { return x; },
		// gets the item to be sorted
		getItem = function (x) {
		  var isObject = x != null && typeof x === "object";
		  var isProp = isObject && this.prop in x;
		  return this.parser(isProp ? x[this.prop] : x);
		};
		
	/**
	 * Sorts an array of elements.
	 *
	 * @param  {Array} array: the collection to sort
	 * @param  {Object} cfg: the configuration options
	 * @property {String}   cfg.prop: property name (if it is an Array of objects)
	 * @property {Boolean}  cfg.desc: determines whether the sort is descending
	 * @property {Function} cfg.parser: function to parse the items to expected type
	 * @return {Array}
	 */
	return function sortby (array, cfg) {
	  if (!(array instanceof Array && array.length)) return [];
	  if (toString.call(cfg) !== "[object Object]") cfg = {};
	  if (typeof cfg.parser !== "function") cfg.parser = parse;
	  cfg.desc = !!cfg.desc ? -1 : 1;
	  return array.sort(function (a, b) {
		a = getItem.call(cfg, a);
		b = getItem.call(cfg, b);
		return cfg.desc * (a < b ? -1 : +(a > b));
	  });
	};
	
  }());

const TimeAgo = (previous, current) => {

    if (!current)
      current = moment();

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - moment(previous);
    var ls_RESP = '';

    if (elapsed < msPerMinute)
      ls_RESP = '' + Math.round(elapsed/1000) + ' seg atrás';   
    else if (elapsed < msPerHour)
      ls_RESP = '' + Math.round(elapsed/msPerMinute) + ' min atrás';   
    else if (elapsed < msPerDay )
      ls_RESP = '' + Math.round(elapsed/msPerHour ) + ' horas atrás';   
    else if (elapsed < msPerMonth)
      ls_RESP = '' + Math.round(elapsed/msPerDay) + ' dias atrás';   
    else if (elapsed < msPerYear)
      ls_RESP = '' + Math.round(elapsed/msPerMonth) + ' meses atrás';   
    else
      ls_RESP = '' + Math.round(elapsed/msPerYear ) + ' anos atrás';   

    return ls_RESP;
}

const getRandomInt = (max) => {
	return Math.floor(Math.random() * max);
}

const generatePDFThumbnails = async(pdf_source, thumbnail_size=150, page=null) => {
	try {
		const thumbnails = await generatePdfThumbnails(pdf_source, thumbnail_size, page);
		if (!thumbnails)
		  return null
		else
			return thumbnails;
		//console.log(thumbnails);
	} catch (err) {
		return null;
	}
}

const validateDate = (data) => {
	const isValidDate = moment(data?.substring(0,10), 'YYYY-MM-DD', true).isValid();
	return isValidDate
}

function buscaSemAcentosArray(array, termo) {
	const regexTermo = new RegExp(termo.replace(/[\^\$\.\*\+\?\(\)\[\]\{\}\|\\\/]/g, '\\$&'), 'gi');
	const semAcentos = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	return array.filter((elemento) => semAcentos(elemento).match(regexTermo));
}

function buscaSemAcentosJSON(array, chave, termo) {
	const regexTermo = new RegExp(termo.replace(/[\^\$\.\*\+\?\(\)\[\]\{\}\|\\\/]/g, '\\$&'), 'gi');
	const semAcentos = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	return array.filter((objeto) => semAcentos(objeto[chave]).match(regexTermo));
}

// get file name from the given url
async function getFileName(url, withoutExtension) {
	if (!url) return "";

	var parts = url.split("\\");
	parts = parts.pop();
	parts = parts.split("/");
	var fileName = parts.pop();  // get the file name from the last part of the url
	fileName = fileName.split("?")[0];

	// get file name without extension
	if (withoutExtension) {
			return fileName.substring(0, fileName.lastIndexOf("."));
	}

	return fileName;
}

// get file extension from the given url
async function getFileExtension(url, withoutDot) {
		if (!url) return null;

		var fileName = fileUtility.getFileName(url);  // get file name from the given url
		var parts = fileName.toLowerCase().split(".");
		return withoutDot ? parts.pop() : "." + parts.pop();  // get the extension from the file name with or without dot
}

// Usado para selecionar o texto consultado e deixar ele HighLight
function highlightAll(doc, keyWords) { 
	doc.getElementById('hid_search_text').value = keyWords; 
	doc.designMode = "on"; 
	var sel = window.getSelection(); 
	sel.collapse(doc.body, 0);
	while (window.find(keyWords)) { 
			doc.execCommand("HiliteColor", false, "yellow"); 
			sel.collapseToEnd(); 
	}
	doc.designMode = "off";
	goTop(keyWords,1); 
}
	
// Usado para retirar a seleção do texto consultado
function removeHighLight(doc) { 
		var keyWords = doc.getElementById('hid_search_text').value; 
		doc.designMode = "on"; 
		var sel = window.getSelection(); 
		sel.collapse(doc.body, 0);
		while (window.find(keyWords)) { 
				doc.execCommand("HiliteColor", false, "transparent"); 
				sel.collapseToEnd(); 
		}
		doc.designMode = "off"; 
		goTop(keyWords,0); 
}

function goTop(keyWords,findFirst) { 
	if(window.document.location.href = '#') { 
			if(findFirst) { 
					window.find(keyWords, 0, 0, 1);
			}
	}
}
function changeNode(n, r, f) {
  f = n.childNodes;
	var c;
	for(c in f)
	  changeNode(f[c], r);
  if (n.data) {
    f = document.createElement('span');
    f.innerHTML = n.data.replace(r, '<span class=search_highlight>$1</span>');
    n.parentNode.insertBefore(f, n);
    n.parentNode.removeChild(n);
  }
}
function searchHighlight(id_elemento_pai, val) {
// console.log("searchHighlight-------")
  var spans = document.getElementsByClassName('search_highlight');
	var elemento  = document.getElementById(id_elemento_pai);
  while (spans.length) {
    var p = spans[0].parentNode;
    p.innerHTML = p.textContent || p.innerText;
  }
  if (val)
		changeNode(elemento, new RegExp('(' + val + ')','gi'));
}

export {
	hexToBase64,
	blobToBase64,
	
	formatNumber,
	formatPrice,
	formatDate,
	formatDateTime,
	to_Number,

	emailIsValid,
	CpfCnpjIsValid,
	telefoneIsValid,
	
	maskCpfCnpj,
	maskCPFCNPJ,
	maskCelular,
	maskTELEFONE,
	removeMaskMoney,
	
	roundToTwo,
	filterArrayByString,
	calculaFinanciamento,
	formatDateToLocaleString,

	handleFileUpload,
	
	getRadDeg,

	nomeIniciais,

	resizeImage,

	groupArrayMultKeys,

	duplicateKeysInArray,
	
	sortBy,

	TimeAgo,

	getRandomInt,

	buscaSemAcentosArray,

	buscaSemAcentosJSON,

	getFileName,
	getFileExtension,

	highlightAll,
	removeHighLight,
	goTop,

	searchHighlight,

	generatePDFThumbnails,

	validateDate
};
