import Vue from "vue";
import { Capacitor } from "@capacitor/core";

function initialState() {
  return {
    cod_empresa                   : null,
    currentTransition             : 'next',        
    step_ModalNegociacao          : 1,
    alert                         : false,
    alert_cor                     : "",
    alert_msg                     : "",
    alert_timeout                 : 3000,
    C_FILTROAVANCADO_VALORMAXIMO  : 1000000,
    ViscomSGI                     : false,
    isMobileDevice                : true,
    alert_cor_vermelho            : '#ecb3a4',
    overlay_global                : false,

    pre_filtro_area       : [ {"id": 0, "text": "0m² até 150.00m²", "min": "0.00", "max": "150.00" },
                              {"id": 1, "text": "150.01m² até 250.00m²", "min": "150.01", "max": "250.00" },
                              {"id": 2, "text": "250.01m² até 350.00m²", "min": "250.01", "max": "350.00" },
                              {"id": 3, "text": "350.01m² até 450.00m²", "min": "350.01", "max": "450.00" },
                              {"id": 4, "text": "450.01m² até 600.00m²", "min": "450.01", "max": "600.00" },
                              {"id": 5, "text": "acima de 600.00m²", "min": "600.01", "max": "999999999" }
                            ],
    pre_filtro_valor      : [ {"id": 0, "text": "R$0,00 até R$50.000,00"        , "min": 0.00     , "max": 50000.00  },
                              {"id": 1, "text": "R$50.000,01 até R$100.000,00"  , "min": 50000.01 , "max": 100000.00 },
                              {"id": 2, "text": "R$100.000,01 até R$150.000,00" , "min": 100000.01, "max": 150000.00 },
                              {"id": 3, "text": "R$150.000,01 até R$200.000,00" , "min": 150000.01, "max": 200000.00 },
                              {"id": 4, "text": "R$200.000,01 até R$300.000,00" , "min": 200000.01, "max": 300000.00 },
                              {"id": 5, "text": "acima de R$300.000,00"         , "min": 300000.01, "max": 999999999 }
                            ],
    pre_filtro_situacao   : 
                            [
                              { "id": 0, text: "Indiferente", value: "" },
                              { "id": 1, text: "Pronto para Morar", value: "Pronto para Morar" },
                              { "id": 2, text: "Em Obras", value: "Em Obras" },
                              { "id": 3, text: "Lançamento", value: "Lançamento" },
                            ]
  }
}

var site = new Vue({

  data () {
    return initialState();
  },

  async created() {
    this.isMobileDevice = await ( Capacitor.getPlatform() === 'android' ||
                                   Capacitor.getPlatform() === 'ios')
  },

  methods: {
    resetData() { 
      Object.assign(this.$data, initialState());
    },
  },

  computed: {
    // isMobileDevice3() {
    //   const lb_Return = async() => {await ( Capacitor.getPlatform() === 'android' ||
    //                                         Capacitor.getPlatform() === 'ios')}
    //   return lb_Return
    // },

    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        console.log('TRUE')
        return true
      } else {
        console.log('FALSE')
        return false
      }
    }

  }

  // watch: {
  //   alert() {
  //     // Alterna tempo de alert snackbar geral para 2500=success ou 5000=erro
  //     // if (!this.alert)
  //     //   this.alert_timeout = 5000
  //     // else
  //     //   this.alert_timeout = 2500
  //   }
  // },
   
})

export default site
