import axios from "axios";
import { Capacitor } from "@capacitor/core";
import url from 'url';

/*
////++++
Stimulsoft.Base.Localization.StiLocalization.addLocalizationFile("sti - 2020/Localizations/pt-BR.xml", false, "Portuguese (Brazil)");
Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("sti - 2020/Localizations/pt-BR.xml", true);
Stimulsoft.StiOptions.Export.Pdf.WinFormsHighAccuracyWordWrap = true;
Stimulsoft.StiOptions.Export.Pdf.reduceFontFileSize = true;
Stimulsoft.StiOptions.Export.Pdf.allowEditablePdf = false;
Stimulsoft.StiOptions.Export.Pdf.useAlternativeFontNames = false;
Stimulsoft.StiOptions.Export.Pdf.allowImportSystemLibraries = true;
Stimulsoft.StiOptions.Export.Pdf.AllowImportSystemLibraries = true;
Stimulsoft.StiOptions.Export.Pdf.allowFontsCache = false;
Stimulsoft.StiOptions.allowImportSystemLibraries = true;
Stimulsoft.StiOptions.AllowImportSystemLibraries = true;
// Roboto Black
// Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/Roboto-Black.ttf");
// Arial
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/arial.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/arialbd.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/arialbi.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ariali.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ARIALN.TTF");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ARIALNB.TTF");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ARIALNBI.TTF");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ARIALNI.TTF");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ariblk.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ARLRDBD.TTF");
// Calibri
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibri.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibrib.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibrii.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibril.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibrili.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibriz.ttf");
// SegoeUI
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeui.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeuib.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeuii.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeuil.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeuisl.ttf");
Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeuiz.ttf");
// Stimulsoft a partir de 2022 -------------------------------------------------------------------------------------------
// Stimulsoft.Base.StiFontCollection.setOpentypeFontsFolder("sti - 2020/fonts/");
////++++
*/

// Stimulsoft 2023 ===============================================================================================================
//window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkcgIvwL0jnpsDqRpWg5FI5kt2G7A0tYIcUygBh1sPs7plofUOqPB1a4HBIXJB621mau2oiAIj+ysU7gKUXfjn/D5BocmduNB+ZMiDGPxFrAp3PoD0nYNkkWh8r7gBZ1v/JZSXGE3bQDrCQCNSy6mgby+iFAMV8/PuZ1z77U+Xz3fkpbm6MYQXYp3cQooLGLUti7k1TFWrnawT0iEEDJ2iRcU9wLqn2g9UiWesEZtKwI/UmEI2T7nv5NbgV+CHguu6QU4WWzFpIgW+3LUnKCT/vCDY+ymzgycw9A9+HFSzARiPzgOaAuQYrFDpzhXV+ZeX31AxWlnzjDWqpfluygSNPtGul5gyNt2CEoJD1Yom0VN9fvRonYsMsimkFFx2AwyVpPcs+JfVBtpPbTcZscnzUdmiIvxv8Gcin6sNSibM6in/uUKFt3bVgW/XeMYa7MLGF53kvBSwi78poUDigA2n12SmghLR0AHxyEDIgZGOTbNI33GWu7ZsPBeUdGu55R8w="
window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkgpgFGkUl79uxVs8X+uspx6K+tqdtOB5G1S6PFPRrlVNvMUiSiNYl724EZbrUAWwAYHlGLRbvxMviMExTh2l9xZJ2xc4K1z3ZVudRpQpuDdFq+fe0wKXSKlB6okl0hUd2ikQHfyzsAN8fJltqvGRa5LI8BFkA/f7tffwK6jzW5xYYhHxQpU3hy4fmKo/BSg6yKAoUq3yMZTG6tWeKnWcI6ftCDxEHd30EjMISNn1LCdLN0/4YmedTjM7x+0dMiI2Qif/yI+y8gmdbostOE8S2ZjrpKsgxVv2AAZPdzHEkzYSzx81RHDzZBhKRZc5mwWAmXsWBFRQol9PdSQ8BZYLqvJ4Jzrcrext+t1ZD7HE1RZPLPAqErO9eo+7Zn9Cvu5O73+b9dxhE2sRyAv9Tl1lV2WqMezWRsO55Q3LntawkPq0HvBkd9f8uVuq9zk7VKegetCDLb0wszBAs1mjWzN+ACVHiPVKIk94/QlCkj31dWCg8YTrT5btsKcLibxog7pv1+2e4yocZKWsposmcJbgG0"

window.Stimulsoft.Base.Localization.StiLocalization.addLocalizationFile("sti-2023/Localizations/pt-BR.xml", false, "Portuguese (Brazil)");
window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("sti-2023/Localizations/pt-BR.xml", true);
window.Stimulsoft.Base.StiFontCollection.setOpentypeFontsFolder("sti-2023/fonts/");

var PORTA = "";

// Se tiver fazendo com porta, significa que está local
if (document.location.port != "")
	PORTA = ':3002'

var BASE_URL_WEBSOCKET 	= "";
var baseURL     				= "";
var baseURL_API 				= "";
var baseURL_REL 				= "";

const padrao_BASE_URL_WEBSOCKET 	= `ws://${document.location.hostname}${PORTA}`;
const padrao_baseURL     			= `${document.location.protocol}//${document.location.hostname}${PORTA}`;
const padrao_baseURL_API 			= `${document.location.protocol}//${document.location.hostname}${PORTA}/api/`;
const padrao_baseURL_REL 			= `${document.location.protocol}//${document.location.hostname}${PORTA}`;

// console.log('padrao_BASE_URL_WEBSOCKET', padrao_BASE_URL_WEBSOCKET);
// console.log('padrao_baseURL', padrao_baseURL);
// console.log('padrao_baseURL_API', padrao_baseURL_API);
// console.log('padrao_baseURL_REL', padrao_baseURL_REL);
// console.log('document.location',document.location);

// const padrao_BASE_URL_WEBSOCKET 	= "ws://localhost:3002";
// const padrao_baseURL     			= "http://localhost:3002/";
// const padrao_baseURL_API 			= "http://localhost:3002/api/";
// const padrao_baseURL_REL 			= "http://localhost:3002/";

// const padrao_BASE_URL_WEBSOCKET 	= "ws://192.168.0.8:3002";
// const padrao_baseURL     					= "http://192.168.0.8:3002/";
// const padrao_baseURL_API 					= "http://192.168.0.8:3002/api/";
// const padrao_baseURL_REL 					= "http://192.168.0.8:3002/";

// const padrao_BASE_URL_WEBSOCKET 	= "ws://186.249.46.46:3003";
// const padrao_baseURL     					= "http://186.249.46.46:3003/";
// const padrao_baseURL_API 					= "http://186.249.46.46:3003/api/";
// const padrao_baseURL_REL 					= "http://186.249.46.46:3003/";

// const padrao_BASE_URL_WEBSOCKET 	= "ws://192.168.100.58:3002";
// const padrao_baseURL     					= "http://192.168.100.58:3002/";
// const padrao_baseURL_API 					= "http://192.168.100.58:3002/api/";
// const padrao_baseURL_REL 					= "http://192.168.100.58:3002/";

// const padrao_BASE_URL_WEBSOCKET 	= "ws://viscom-sgi.younect.com.br:3002";
// const padrao_baseURL 							= "http://viscom-sgi.younect.com.br:3002/";
// const padrao_baseURL_API 					= "http://viscom-sgi.younect.com.br:3002/api/";
// const padrao_baseURL_REL 					= "http://viscom-sgi.younect.com.br:3002/";

// const padrao_BASE_URL_WEBSOCKET 	= "ws://viscom:3002";
// const padrao_baseURL 							= "http://viscom:3002/";
// const padrao_baseURL_API 					= "http://viscom:3002/api/";
// const padrao_baseURL_REL 					= "http://viscom:3002/";

// -- Aplicação DEMO ----------------------------------------------
// const padrao_BASE_URL_WEBSOCKET 	= "wss://demo.younect.com.br";
// const padrao_baseURL 							= "https://demo.younect.com.br/";
// const padrao_baseURL_API 					= "https://demo.younect.com.br/api/";
// const padrao_baseURL_REL 					= "https://demo.younect.com.br/";

// -- Aplicação PRODUÇÂO ----------------------------------------------
// const padrao_BASE_URL_WEBSOCKET 	= "wss://app-imob.younect.com.br";
// const padrao_baseURL 							= "https://app-imob.younect.com.br/";
// const padrao_baseURL_API 					= "https://app-imob.younect.com.br/api/";
// const padrao_baseURL_REL 					= "https://app-imob.younect.com.br/";

// -- Aplicação PRODUÇÂO 2.0 (por enquanto somente a rvr) ----------------------------------------------
// const padrao_BASE_URL_WEBSOCKET 				= "wss://rvr.younect.com.br";
// const padrao_baseURL 						= "https://rvr.younect.com.br/";
// const padrao_baseURL_API 					= "https://rvr.younect.com.br/api/";
// const padrao_baseURL_REL 					= "https://rvr.younect.com.br/";

var API = axios.create({
	baseURL: padrao_baseURL_API,
	responseType: "json",
});
var API_BLOB = axios.create({
	baseURL: padrao_baseURL_API,
	responseType: "blob",
});
var API_REL = axios.create({
	baseURL: padrao_baseURL_REL,
	responseType: "blob",
});
var API_REL_BASE = axios.create({
	baseURL: padrao_baseURL,
	responseType: "text/html",
});

// HEADERS DEFAULT
const headersDefault = () => {
	let headers = {};
	headers["Content-Type"] = "application/json";

	// "Content-Type": "application/json",
	// "Access-Control-Allow-Origin": "*",
	// "Access-Control-Allow-Methods": "OPTIONS",
	// "Access-Control-Allow-Headers": "Content-Type, Authorization",
	// "Access-Control-Allow-Credentials": "true"
	// headers["Access-Control-Allow-Origin"] = "*"
	// headers["Access-Control-Allow-Methods"] = "OPTIONS";
	// headers["Access-Control-Allow-Headers"] = "Content-Type, Authorization";
	// headers["Access-Control-Allow-Credentials"] = "true";

	//headers["x-access-site"] = window.location.hostname;

	// Se não tiver token definido passar somente um token com os parametrôes deste usuario
	let token = localStorage.getItem("@YouNect-Imob-" + window.location.hostname + ":token");
    if (!token || token == "undefined") {
		headers["x-access-token"] = "";
		//console.log('headersDefault - SEM Token',  headers["x-access-token"]);
	}
	// Se tiver logado então passar o token de quem está logado
	else {
		headers["x-access-token"] = token;
		//console.log('headersDefault - COM Token', headers["x-access-token"]);
	}

	//console.log('1 - headersDefault', token);
	headers["x-access-plataform"] = Capacitor.getPlatform();

	return headers;
};

// REQUEST INTERCEPTOR
API.interceptors.request.use((config) => {
	config.params = config.params || {};
	config.headers = headersDefault();
	return config;
});
API_BLOB.interceptors.request.use((config) => {
	config.params = config.params || {};
	config.headers = headersDefault();
	return config;
});
API_REL.interceptors.request.use((config) => {
	config.params = config.params || {};
	config.headers = headersDefault();
	return config;
});
API_REL_BASE.interceptors.request.use((config) => {
	config.params = config.params || {};
	config.headers = headersDefault();
	return config;
});

const API_MUDAR = (p_protocol, p_host, p_porta) => {
	if (p_host) {
		var ls_porta = '';
		if (p_porta)
			ls_porta = ":" + p_porta

		if (p_protocol == 'https:') {
	  		BASE_URL_WEBSOCKET 	= "wss://" + p_host + ls_porta
		}
		else
			BASE_URL_WEBSOCKET 	= "ws://" + p_host + ":" + p_porta;
		baseURL     		= p_protocol + "//" + p_host + ls_porta + "/";
		baseURL_API 		= p_protocol + "//" + p_host + ls_porta + "/api/";
		baseURL_REL			= p_protocol + "//" + p_host + ls_porta + "/";
	}
	else {
		BASE_URL_WEBSOCKET 	= padrao_BASE_URL_WEBSOCKET;
		baseURL     		= padrao_baseURL;
		baseURL_API 		= padrao_baseURL_API;
		baseURL_REL			= padrao_baseURL_REL;
	}

	API = axios.create({
		baseURL: baseURL_API,
		responseType: "json",
	});
	API_BLOB = axios.create({
		baseURL: baseURL_API,
		responseType: "blob",
	});
	API_REL = axios.create({
		baseURL: baseURL_REL,
		responseType: "blob",
	});
	API_REL_BASE = axios.create({
		baseURL: baseURL,
		responseType: "text/html",
	});

	// REQUEST INTERCEPTOR
	API.interceptors.request.use((config) => {
		config.params = config.params || {};
		config.headers = headersDefault();
		return config;
	});
	API_BLOB.interceptors.request.use((config) => {
		config.params = config.params || {};
		config.headers = headersDefault();
		return config;
	});
	API_REL.interceptors.request.use((config) => {
		config.params = config.params || {};
		config.headers = headersDefault();
		return config;
	});
	API_REL_BASE.interceptors.request.use((config) => {
		config.params = config.params || {};
		config.headers = headersDefault();
		return config;
	});

	//RESPONSE INTERCEPTOR
	API.interceptors.response.use(
		(response) => responseInterceptor(response),
		(error) => errorInterceptor(error)
	);
	API_BLOB.interceptors.response.use(
		(response) => responseInterceptor(response),
		(error) => errorInterceptor(error)
	);
	API_REL_BASE.interceptors.response.use(
		(response) => responseInterceptor(response),
		(error) => errorInterceptor(error)
	);

	window.StiOptions.WebServer.url = baseURL;
	//console.log('StiOptions.WebServer.url', StiOptions.WebServer.url)

	return  BASE_URL_WEBSOCKET + ' / ' +
			baseURL + ' / ' +
			baseURL_API + ' / ' +
			baseURL_REL + ' / ';

}

// RETURN REPONSE
const responseInterceptor = (response) => {
	if (response.data && response.data.token && response.data.token != 'undefined') {
		localStorage.setItem("@YouNect-Imob-" + window.location.hostname + ":token", response.data.token);
		//console.log("responseInterceptor 2 - SETOU TOKEN =================", response.data.token, response);
		//let buff = new Buffer(response.data.token, 'base64');
		//let text = buff.toString('ascii');
	}
	else {
		//console.log("responseInterceptor 2 - NAO SETOU TOKEN =================", response);
		//localStorage.removeItem("@YouNect-Imob-" + window.location.hostname + ":token");
	}
	return response;
};

// RETURN ERROR
const errorInterceptor = (error) => {
	//console.log("responseInterceptor 2 - NAO SETOU TOKEN =================", response);
	// Não autorizado ------------------------------------------------------------
	if (error.response && error.response.status && error.response.status == 401) {
		localStorage.removeItem("@YouNect-Imob-" + window.location.hostname + ":token");
		API_MUDAR();
		//router.push("/");
		window.location = "/";

		// originalRequest._retry = true;
		// return axios
		// 	.post(`${baseURL_API}/refresh_token`, {
		// 		refresh_token: token,
		// 	})
		// 	.then((res) => {
		// 		if (res.status === 200) {
		// 			localStorage.setItem("@YouNect-Imob-" + window.location.hostname + ":token", res.data.token);

		// 			axios.defaults.headers.common["x-access-token"] = res.data.token;

		// 			return axios(originalRequest);
		// 		}
		// 	});
	}

	return error.response;
};

//RESPONSE INTERCEPTOR
API.interceptors.response.use(
	(response) => responseInterceptor(response),
	(error) => errorInterceptor(error)
);
API_BLOB.interceptors.response.use(
	(response) => responseInterceptor(response),
	(error) => errorInterceptor(error)
);
API_REL_BASE.interceptors.response.use(
	(response) => responseInterceptor(response),
	(error) => errorInterceptor(error)
);

const formatNumber = (number) => {
	if (number || number === 0) {
		return number
			.toFixed(2)
			.replace(".", ",")
			.split("")
			.reverse()
			.map((v, i) => (i > 5 && (i + 6) % 3 === 0 ? `${v}.` : v))
			.reverse()
			.join("");
	} else {
		return number;
	}
};

// Verifica se usuário estiver logado, então mudar a API, senão deixar Padrão
let user = localStorage.getItem("@YouNect-Imob-" + window.location.hostname + ":user");
if (!user || !user || user == "undefined") {
	//console.log('_______ NAO LOGADO');
	localStorage.removeItem("@YouNect-Imob-" + window.location.hostname + ":token");
	localStorage.removeItem("@YouNect-Imob-" + window.location.hostname + ":user");
	API_MUDAR();
}
// Se tiver logado então passar o token de quem está logado
else {
	try {
		user = JSON.parse(user)
		if (!user || !user.url_api) {
			localStorage.removeItem("@YouNect-Imob-" + window.location.hostname + ":token");
			localStorage.removeItem("@YouNect-Imob-" + window.location.hostname + ":user");
			API_MUDAR();
		}
		else {
			const myURL = url.parse(user.url_api);
			//console.log('_______ LOGADO', user.url_api, myURL.protocol, myURL.hostname, myURL.port);
			API_MUDAR(myURL.protocol, myURL.hostname, myURL.port);
		}
	}
	catch (err) {
		console.log('[ERRO]: Erro de login API ', err);
		localStorage.removeItem("@YouNect-Imob-" + window.location.hostname + ":token");
		localStorage.removeItem("@YouNect-Imob-" + window.location.hostname + ":user");
		API_MUDAR();
	}
}

export  { 	baseURL,
			baseURL_API,
			API_MUDAR,
			API,
			API_REL,
			API_REL_BASE,
			API_BLOB,
			/* API_ZAP, */
			BASE_URL_WEBSOCKET,
			formatNumber
		};
