const C_SENHA_SGI			= "YT&$#EE&jhlkhj45%4F##&*"

// const COR_PRINCIPAL = "rgba(0, 50, 90, 0.60)";
// const COR_PRINCIPAL = "rgba(5, 72, 163, 0.80)"; // = "#0548a3"
// const COR_PRINCIPAL = "#376db5"
// const COR_PRINCIPAL = "#0548a3";
const COR_PRINCIPAL = "#446995" // Mesma do OnlyOffice
// const COR_PRINCIPAL = "#507090"; //-- Cinza/azul bonito
// const COR_PRINCIPAL = "#245274"; // Mesma do Site https://vhsys.com.br/
// const COR_PRINCIPAL = "#C0C2CA";
// const COR_PRINCIPAL = "#35518f";

const COR_ACAO = {
	"Aprovado"			: "#93B673",
	"Reprovado"			: "#D00000",
	"Aguardando"		: "#c0c0c0",
	"Cancelado"			: "#FF5050",
	"Documentacao"  : "#2e8b57",
}

// const COR_SECUNDARIA 		= "#F7F7F9"; // Cor de Fundo de Card, Panel, ou o componente que abriga os campos, dados, etc  = Cinza pouco mais claro
// const COR_SECUNDARIA 		= "#f1f4fb"; // Mesma do Site https://vhsys.com.br/
const COR_SECUNDARIA 		= "#EFEFF3";

const COR_SECUNDARIA_2 		= "#F0F1F4"; // Cor de Fundo de Card, Panel, ou o componente que abriga os campos, dados, etc  = Cinza pouco mais claro
// const COR_BACKGROUND 		= "#FAFAFF"; // Cor de Fundo da Página = Cinza mais escuro / azulado
// const COR_BACKGROUND 		= "#ECECF5"; // Cor de Fundo da Página = Cinza mais escuro / azulado
const COR_BACKGROUND 		= "#FAFAFB"; // Cor de Fundo da Página = Cinza mais escuro / segue padrão do SECUNDARIA
// const COR_BACKGROUND 		= "#EAEAEA"; // Cor de Fundo da Página = Cinza mais escuro / segue padrão do SECUNDARIA
const COR_BACKGROUND_2 		= "#F0F2F9"; // Cor de Fundo das Laterais, cabecalhos, etc
const COR_SUBTITULO  		= "#6185c1"; // Cor dos Subtitulos ou Titulos das Grades
const COR_PLACEHOLDER  		= "#91A9D1";
const COR_BACKGROUND_TEXT 	= "#FFFFFF";
const COR_SOMBRA 			= "#A6A6A6"; // Cor das sombras das bordas substituindo a propriedade elevation
const COR_BORDA 			= "#E0E0E0"; // Cor das bordas de componentes tipo ; grade, caixas, etc

// Documentos e Modelos do Word, Excel, etc -----------------
const PATH_EDITOR_DOCUMENTOS             = "editor_documentos";
const PATH_EDITOR_DOCUMENTOS_THUMB       = "editor_documentos_thumb";

const menuItems = [
	{title: "Painel de Controle", path: "/painel", icon: "mdi-view-dashboard", funcoes: [1,2,3,4,5,6,7]},
	{title: "Empreendimentos", path: "/empreendimentos", icon: "mdi-google-maps", funcoes: [1,2,3,4,5,6,7]},
	{title: "Empreendimento", path: "/empreendimentoconsulta", icon: "mdi-chart-box-outline", funcoes: [2,4,5,6]},
	{title: "Usuarios", path: "/usuarioconsulta", icon: "mdi-folder-account", funcoes: [2,4,5,6]},
	{title: "Contatos", path: "/contatoconsulta", icon: "mdi-account-multiple-plus", funcoes: [3,4,5,6]},
	{title: "Pessoas", path: "/pessoaconsulta", icon: "mdi-account-multiple-plus", funcoes: [2,4,5,6]},
	{title: "Negociações", path: "/tasks", icon: "mdi-chart-bar-stacked", funcoes: [2,4,5,6]},
	{title: "Configurações", path: "/configuracoes", icon: "mdi-account-multiple-plus-outline", funcoes: [4,5,6]},
	{title: "Relatorios", path: "/relatorios", icon: "mdi-chart-box-outline", funcoes: [2,4,5,6]},
];

const arrayEstadoCivil = [
	{ codigo: 1, name: "Solteiro(a)", value: "Solteiro(a)" },
	{ codigo: 2, name: "Casado(a)", value: "Casado(a)" },
	{ codigo: 3, name: "Separado(a) Judicialmente", value: "Separado(a) Judicialmente" },
	{ codigo: 4, name: "Divorciado(a)", value: "Divorciado(a)" },
	{ codigo: 5, name: "Viúvo(a)", value: "Viúvo(a)" },
	{ codigo: 6, name: "Desquitado(a)", value: "Desquitado(a)" },
	{ codigo: 7, name: "União Estável(a)", value: "União Estável(a)" },
];

const arrayRegimeBens = [
    { codigo: 1, name: "comunhão parcial de bens antes da lei 6.515/77", value: 1 },
	{ codigo: 2, name: "comunhão parcial de bens na vigência da lei 6.515/77", value: 2 },
	{ codigo: 3, name: "comunhão universal de bens antes da lei 6.515/77", value: 3 },
	{ codigo: 4, name: "comunhão universal de bens na vigência da lei 6.515/77", value: 4 },
	{ codigo: 5, name: "separação total de bens antes da lei 6.515/77", value: 5 },
	{ codigo: 6, name: "separação total de bens na vigência da lei 6.515/77", value: 6 },	
	{ codigo: 7, name: "união estável (amazeados)", value: 7 },
];

const arraySexo = [
	{ name: "Masculino", value: "M" },
	{ name: "Feminino", value: "F" },
];

const arrayAtivoInativo = [
	{ name: "ATIVO", value: "ATIVO" },
	{ name: "INATIVO", value: "INATIVO" },
];

const arraySIM_NAO = [
	{ name: "Sim", value: "S" },
	{ name: "Não", value: "N" },
];

const arrayEnderecoTipo = [
	{ name: "Residencial", value: "Residencial" },
	{ name: "Comercial", value: "Comercial" },
];

const options_vuetifymoney = {
	locale: "pt-BR",
	prefix: "R$",
	suffix: "",
	length: 9,
	precision: 2
  }

  const arrayPaises = [
	{codigo: 30, sigla: "AFG", nome: "Afeganistão"},
	{codigo: 31, sigla: "ZAF", nome: "África do Sul"},
	{codigo: 32, sigla: "ALB", nome: "Albânia"},
	{codigo: 33, sigla: "DEU", nome: "Alemanha"},
	{codigo: 34, sigla: "AND", nome: "Andorra"},
	{codigo: 35, sigla: "AGO", nome: "Angola"},
	{codigo: 36, sigla: "AIA", nome: "Anguilla"},
	{codigo: 37, sigla: "ATG", nome: "Antígua e Barbuda"},
	{codigo: 38, sigla: "SAU", nome: "Arábia Saudita"},
	{codigo: 39, sigla: "DZA", nome: "Argélia"},
	{codigo: 40, sigla: "ARG", nome: "Argentina"},
	{codigo: 41, sigla: "ARM", nome: "Armênia"},
	{codigo: 42, sigla: "ABW", nome: "Aruba"},
	{codigo: 43, sigla: "AUS", nome: "Austrália"},
	{codigo: 44, sigla: "AUT", nome: "Áustria"},
	{codigo: 45, sigla: "AZE", nome: "Azerbaijão"},
	{codigo: 46, sigla: "BHS", nome: "Bahamas"},
	{codigo: 47, sigla: "BGD", nome: "Bangladesh"},
	{codigo: 48, sigla: "BRB", nome: "Barbados"},
	{codigo: 49, sigla: "BHR", nome: "Barein"},
	{codigo: 50, sigla: "BEL", nome: "Bélgica"},
	{codigo: 51, sigla: "BLZ", nome: "Belize"},
	{codigo: 52, sigla: "BEN", nome: "Benin"},
	{codigo: 53, sigla: "BMU", nome: "Bermuda"},
	{codigo: 54, sigla: "BLR", nome: "Bielorússia"},
	{codigo: 55, sigla: "BOL", nome: "Bolívia"},
	{codigo: 56, sigla: "BES", nome: "Bonaire, Santo Eustáquio e Saba"},
	{codigo: 57, sigla: "BIH", nome: "Bósnia-Herzegovina"},
	{codigo: 58, sigla: "BWA", nome: "Botsuana"},
	{codigo: 59, sigla: "BRA", nome: "Brasil"},
	{codigo: 60, sigla: "BRN", nome: "Brunei"},
	{codigo: 61, sigla: "BGR", nome: "Bulgária"},
	{codigo: 62, sigla: "BFA", nome: "Burkina Faso"},
	{codigo: 63, sigla: "BDI", nome: "Burundi"},
	{codigo: 64, sigla: "BTN", nome: "Butão"},
	{codigo: 65, sigla: "CPV", nome: "Cabo Verde"},
	{codigo: 66, sigla: "CMR", nome: "Camarões"},
	{codigo: 67, sigla: "KHM", nome: "Camboja"},
	{codigo: 68, sigla: "CAN", nome: "Canadá"},
	{codigo: 69, sigla: "KAZ", nome: "Casaquistão"},
	{codigo: 70, sigla: "QAT", nome: "Catar"},
	{codigo: 71, sigla: "TCD", nome: "Chade"},
	{codigo: 72, sigla: "CHL", nome: "Chile"},
	{codigo: 73, sigla: "CHN", nome: "China"},
	{codigo: 74, sigla: "HKG", nome: "China, Hong Kong"},
	{codigo: 75, sigla: "MAC", nome: "China, Macao"},
	{codigo: 76, sigla: "CYP", nome: "Chipre"},
	{codigo: 77, sigla: "SGP", nome: "Cingapura"},
	{codigo: 78, sigla: "COL", nome: "Colômbia"},
	{codigo: 79, sigla: "COM", nome: "Comores"},
	{codigo: 80, sigla: "COG", nome: "Congo"},
	{codigo: 81, sigla: "PRK", nome: "Coréia do Norte"},
	{codigo: 82, sigla: "KOR", nome: "Coréia do Sul"},
	{codigo: 83, sigla: "CIV", nome: "Costa do Marfim"},
	{codigo: 84, sigla: "CRI", nome: "Costa Rica"},
	{codigo: 85, sigla: "HRV", nome: "Croácia"},
	{codigo: 86, sigla: "CUB", nome: "Cuba"},
	{codigo: 87, sigla: "CUW", nome: "Curaçao"},
	{codigo: 88, sigla: "DNK", nome: "Dinamarca"},
	{codigo: 89, sigla: "DJI", nome: "Djibouti"},
	{codigo: 90, sigla: "DMA", nome: "Dominica"},
	{codigo: 91, sigla: "EGY", nome: "Egito"},
	{codigo: 92, sigla: "SLV", nome: "El Salvador"},
	{codigo: 93, sigla: "ARE", nome: "Emirados Árabes Unidos"},
	{codigo: 94, sigla: "ECU", nome: "Equador"},
	{codigo: 95, sigla: "ERI", nome: "Eritréia"},
	{codigo: 96, sigla: "SVK", nome: "Eslováquia"},
	{codigo: 97, sigla: "SVN", nome: "Eslovênia"},
	{codigo: 98, sigla: "ESP", nome: "Espanha"},
	{codigo: 99, sigla: "USA", nome: "Estados Unidos da América"},
	{codigo: 100, sigla: "EST", nome: "Estônia"},
	{codigo: 101, sigla: "ETH", nome: "Etiópia"},
	{codigo: 102, sigla: "FJI", nome: "Fiji"},
	{codigo: 103, sigla: "PHL", nome: "Filipinas"},
	{codigo: 104, sigla: "FIN", nome: "Finlândia"},
	{codigo: 105, sigla: "FRA", nome: "França"},
	{codigo: 106, sigla: "GAB", nome: "Gabão"},
	{codigo: 107, sigla: "GMB", nome: "Gâmbia"},
	{codigo: 108, sigla: "GHA", nome: "Gana"},
	{codigo: 109, sigla: "GEO", nome: "Geórgia"},
	{codigo: 110, sigla: "GIB", nome: "Gibraltar"},
	{codigo: 111, sigla: "GRD", nome: "Granada"},
	{codigo: 112, sigla: "GRC", nome: "Grécia"},
	{codigo: 113, sigla: "GRL", nome: "Groenlândia"},
	{codigo: 114, sigla: "GUM", nome: "Guam"},
	{codigo: 115, sigla: "GTM", nome: "Guatemala"},
	{codigo: 116, sigla: "GGY", nome: "Guernsey"},
	{codigo: 117, sigla: "GUY", nome: "Guiana"},
	{codigo: 118, sigla: "GUF", nome: "Guiana Francesa"},
	{codigo: 119, sigla: "GIN", nome: "Guiné"},
	{codigo: 120, sigla: "GNQ", nome: "Guiné Equatorial"},
	{codigo: 121, sigla: "GNB", nome: "Guiné-Bissau"},
	{codigo: 122, sigla: "HTI", nome: "Haiti"},
	{codigo: 123, sigla: "NLD", nome: "Holanda"},
	{codigo: 124, sigla: "HND", nome: "Honduras"},
	{codigo: 125, sigla: "HUN", nome: "Hungria"},
	{codigo: 126, sigla: "YEM", nome: "Iêmen"},
	{codigo: 127, sigla: "IMN", nome: "Ilha de Man"},
	{codigo: 128, sigla: "GLP", nome: "Ilha Guadalupe"},
	{codigo: 129, sigla: "NFK", nome: "Ilha Norfolk"},
	{codigo: 130, sigla: "REU", nome: "Ilha Reunião"},
	{codigo: 131, sigla: "ALA", nome: "Ilhas Alanda"},
	{codigo: 132, sigla: "CYM", nome: "Ilhas Cayman"},
	{codigo: 133, sigla: "COK", nome: "Ilhas Cook"},
	{codigo: 134, sigla: "CCK", nome: "Ilhas do Canal"},
	{codigo: 135, sigla: "FRO", nome: "Ilhas Faeroe"},
	{codigo: 136, sigla: "FLK", nome: "Ilhas Falkland"},
	{codigo: 137, sigla: "MNP", nome: "Ilhas Marianas"},
	{codigo: 138, sigla: "MHL", nome: "Ilhas Marshall"},
	{codigo: 139, sigla: "SLB", nome: "Ilhas Salomão"},
	{codigo: 140, sigla: "TCA", nome: "Ilhas Turks e Caicos"},
	{codigo: 141, sigla: "VIR", nome: "Ilhas Virgens"},
	{codigo: 142, sigla: "VGB", nome: "Ilhas Virgens Britânicas"},
	{codigo: 143, sigla: "WLF", nome: "Ilhas Wallis e Futuna"},
	{codigo: 144, sigla: "IND", nome: "Índia"},
	{codigo: 145, sigla: "IDN", nome: "Indonésia"},
	{codigo: 146, sigla: "IRN", nome: "Irã"},
	{codigo: 147, sigla: "IRQ", nome: "Iraque"},
	{codigo: 148, sigla: "IRL", nome: "Irlanda"},
	{codigo: 149, sigla: "ISL", nome: "Islândia"},
	{codigo: 150, sigla: "ISR", nome: "Israel"},
	{codigo: 151, sigla: "ITA", nome: "Itália"},
	{codigo: 152, sigla: "JAM", nome: "Jamaica"},
	{codigo: 153, sigla: "JPN", nome: "Japão"},
	{codigo: 154, sigla: "JEY", nome: "Jersey"},
	{codigo: 155, sigla: "JOR", nome: "Jordânia"},
	{codigo: 156, sigla: "KIR", nome: "Kiribati"},
	{codigo: 157, sigla: "KWT", nome: "Kuwait"},
	{codigo: 158, sigla: "LAO", nome: "Laos"},
	{codigo: 159, sigla: "LSO", nome: "Lesoto"},
	{codigo: 160, sigla: "LVA", nome: "Letônia"},
	{codigo: 161, sigla: "LBN", nome: "Líbano"},
	{codigo: 162, sigla: "LBR", nome: "Libéria"},
	{codigo: 163, sigla: "LBY", nome: "Líbia"},
	{codigo: 164, sigla: "LIE", nome: "Liechtenstein"},
	{codigo: 165, sigla: "LTU", nome: "Lituânia"},
	{codigo: 166, sigla: "LUX", nome: "Luxemburgo"},
	{codigo: 167, sigla: "MKD", nome: "Macedônia"},
	{codigo: 168, sigla: "MDG", nome: "Madagáscar"},
	{codigo: 169, sigla: "MYS", nome: "Malásia"},
	{codigo: 170, sigla: "MWI", nome: "Malauí"},
	{codigo: 171, sigla: "MDV", nome: "Maldivas"},
	{codigo: 172, sigla: "MLI", nome: "Mali"},
	{codigo: 173, sigla: "MLT", nome: "Malta"},
	{codigo: 174, sigla: "MAR", nome: "Marrocos"},
	{codigo: 175, sigla: "MTQ", nome: "Martinica"},
	{codigo: 176, sigla: "MUS", nome: "Maurício"},
	{codigo: 177, sigla: "MRT", nome: "Mauritânia"},
	{codigo: 178, sigla: "MYT", nome: "Mayotte"},
	{codigo: 179, sigla: "MEX", nome: "México"},
	{codigo: 180, sigla: "MMR", nome: "Mianma"},
	{codigo: 181, sigla: "FSM", nome: "Micronésia"},
	{codigo: 182, sigla: "MOZ", nome: "Moçambique"},
	{codigo: 183, sigla: "MDA", nome: "Moldávia"},
	{codigo: 184, sigla: "MCO", nome: "Mônaco"},
	{codigo: 185, sigla: "MNG", nome: "Mongólia"},
	{codigo: 186, sigla: "MNE", nome: "Montenegro"},
	{codigo: 187, sigla: "MSR", nome: "Montserrat"},
	{codigo: 188, sigla: "NAM", nome: "Namíbia"},
	{codigo: 189, sigla: "NRU", nome: "Nauru"},
	{codigo: 190, sigla: "NPL", nome: "Nepal"},
	{codigo: 191, sigla: "NIC", nome: "Nicarágua"},
	{codigo: 192, sigla: "NER", nome: "Níger"},
	{codigo: 193, sigla: "NGA", nome: "Nigéria"},
	{codigo: 194, sigla: "NIU", nome: "Niue"},
	{codigo: 195, sigla: "NOR", nome: "Noruega"},
	{codigo: 196, sigla: "NCL", nome: "Nova Caledônia"},
	{codigo: 197, sigla: "NZL", nome: "Nova Zelândia"},
	{codigo: 198, sigla: "OMN", nome: "Omã"},
	{codigo: 199, sigla: "PLW", nome: "Palau"},
	{codigo: 200, sigla: "PSE", nome: "Palestina"},
	{codigo: 201, sigla: "PAN", nome: "Panamá"},
	{codigo: 202, sigla: "PNG", nome: "Papua Nova Guiné"},
	{codigo: 203, sigla: "PAK", nome: "Paquistão"},
	{codigo: 204, sigla: "PRY", nome: "Paraguai"},
	{codigo: 205, sigla: "PER", nome: "Peru"},
	{codigo: 206, sigla: "PCN", nome: "Pitcairn"},
	{codigo: 207, sigla: "PYF", nome: "Polinésia Francesa"},
	{codigo: 208, sigla: "POL", nome: "Polônia"},
	{codigo: 209, sigla: "PRI", nome: "Porto Rico"},
	{codigo: 210, sigla: "PRT", nome: "Portugal"},
	{codigo: 211, sigla: "KEN", nome: "Quênia"},
	{codigo: 212, sigla: "KGZ", nome: "Quirguistão"},
	{codigo: 213, sigla: "GBR", nome: "Reino Unido"},
	{codigo: 214, sigla: "CAF", nome: "República Centro Africana"},
	{codigo: 215, sigla: "COD", nome: "República Democrática do Congo"},
	{codigo: 216, sigla: "DOM", nome: "República Dominicana"},
	{codigo: 217, sigla: "CZE", nome: "República Tcheca"},
	{codigo: 218, sigla: "ROU", nome: "Romênia"},
	{codigo: 219, sigla: "RWA", nome: "Ruanda"},
	{codigo: 220, sigla: "RUS", nome: "Rússia (Federação Russa)"},
	{codigo: 221, sigla: "ESH", nome: "Saara Ocidental"},
	{codigo: 222, sigla: "MAF", nome: "Saint Martin (parte francesa)"},
	{codigo: 223, sigla: "SXM", nome: "Saint Martin (parte holandesa)"},
	{codigo: 224, sigla: "SPM", nome: "Saint Pierre e Miquelon"},
	{codigo: 225, sigla: "WSM", nome: "Samoa"},
	{codigo: 226, sigla: "ASM", nome: "Samoa Americana"},
	{codigo: 227, sigla: "SMR", nome: "San Marino"},
	{codigo: 228, sigla: "SHN", nome: "Santa Helena"},
	{codigo: 229, sigla: "LCA", nome: "Santa Lúcia"},
	{codigo: 230, sigla: "BLM", nome: "São Bartolomeu"},
	{codigo: 231, sigla: "KNA", nome: "São Cristóvão e Nevis"},
	{codigo: 232, sigla: "STP", nome: "São Tome e Príncipe"},
	{codigo: 233, sigla: "VCT", nome: "São Vicente e Granadinas"},
	{codigo: 234, sigla: "SRK", nome: "Sark"},
	{codigo: 235, sigla: "SYC", nome: "Seichelles"},
	{codigo: 236, sigla: "SEN", nome: "Senegal"},
	{codigo: 237, sigla: "SLE", nome: "Serra Leoa"},
	{codigo: 238, sigla: "SRB", nome: "Sérvia"},
	{codigo: 239, sigla: "SYR", nome: "Síria"},
	{codigo: 240, sigla: "SOM", nome: "Somália"},
	{codigo: 241, sigla: "LKA", nome: "Sri Lanka"},
	{codigo: 242, sigla: "SWZ", nome: "Suazilândia"},
	{codigo: 243, sigla: "SDN", nome: "Sudão"},
	{codigo: 244, sigla: "SSD", nome: "Sudão do Sul"},
	{codigo: 245, sigla: "SWE", nome: "Suécia"},
	{codigo: 246, sigla: "CHE", nome: "Suíça"},
	{codigo: 247, sigla: "SUR", nome: "Suriname"},
	{codigo: 248, sigla: "SJM", nome: "Svalbard e Jan Mayen Islands"},
	{codigo: 249, sigla: "TJK", nome: "Tadjiquistão"},
	{codigo: 250, sigla: "THA", nome: "Tailândia"},
	{codigo: 251, sigla: "TZA", nome: "Tanzânia"},
	{codigo: 252, sigla: "TLS", nome: "Timor Leste"},
	{codigo: 253, sigla: "TGO", nome: "Togo"},
	{codigo: 254, sigla: "TKL", nome: "Tokelau"},
	{codigo: 255, sigla: "TON", nome: "Tonga"},
	{codigo: 256, sigla: "TTO", nome: "Trinidad e Tobago"},
	{codigo: 257, sigla: "TUN", nome: "Tunísia"},
	{codigo: 258, sigla: "TKM", nome: "Turcomenistão"},
	{codigo: 259, sigla: "TUR", nome: "Turquia"},
	{codigo: 260, sigla: "TUV", nome: "Tuvalu"},
	{codigo: 261, sigla: "UKR", nome: "Ucrânia"},
	{codigo: 262, sigla: "UGA", nome: "Uganda"},
	{codigo: 263, sigla: "URY", nome: "Uruguai"},
	{codigo: 264, sigla: "UZB", nome: "Uzbequistão"},
	{codigo: 265, sigla: "VUT", nome: "Vanuatu"},
	{codigo: 266, sigla: "VAT", nome: "Vaticano"},
	{codigo: 267, sigla: "VEN", nome: "Venezuela"},
	{codigo: 268, sigla: "VNM", nome: "Vietnã"},
	{codigo: 269, sigla: "ZMB", nome: "Zâmbia"},
	{codigo: 270, sigla: "ZWE", nome: "Zimbábue"},
  ]

  const arrayUF = [
	{ name: "AC", value: "Acre" },
	{ name: "AL", value: "Alagoas" },
	{ name: "AP", value: "Amapá" },
	{ name: "AM", value: "Amazonas" },
	{ name: "BA", value: "Bahia" },
	{ name: "CE", value: "Ceará" },
	{ name: "DF", value: "Distrito Federal" },
	{ name: "ES", value: "Espírito Santo" },
	{ name: "GO", value: "Goiás" },
	{ name: "MA", value: "Maranhão" },
	{ name: "MT", value: "Mato Grosso" },
	{ name: "MS", value: "Mato Grosso do Sul" },
	{ name: "MG", value: "Minas Gerais" },
	{ name: "PA", value: "Pará" },
	{ name: "PB", value: "Paraíba" },
	{ name: "PR", value: "Paraná" },
	{ name: "PE", value: "Pernambuco" },
	{ name: "PI", value: "Piauí" },
	{ name: "RJ", value: "Rio de Janeiro" },
	{ name: "RN", value: "Rio Grande do Norte" },
	{ name: "RS", value: "Rio Grande do Sul" },
	{ name: "RO", value: "Rondônia" },
	{ name: "RR", value: "Roraima" },
	{ name: "SC", value: "Santa Catarina" },
	{ name: "SP", value: "São Paulo" },
	{ name: "SE", value: "Sergipe" },
	{ name: "TO", value: "Tocantins" }
];

const MASK_CPFCNPJ  = ['###.###.###-##', '##.###.###/####-##'];
const MASK_TELEFONE = ['(##)####-####', '(##)#####-####'];
const MASK_DATA 	= ['##/##/####', '##/##/####'];
const MASK_CEP 		= ['#####-###', '#####-###'];
const IMG_BRANCO 	= "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gIoSUNDX1BST0ZJTEUAAQEAAAIYAAAAAAIQAABtbnRyUkdCIFhZWiAAAAAAAAAAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAAHRyWFlaAAABZAAAABRnWFlaAAABeAAAABRiWFlaAAABjAAAABRyVFJDAAABoAAAAChnVFJDAAABoAAAAChiVFJDAAABoAAAACh3dHB0AAAByAAAABRjcHJ0AAAB3AAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAFgAAAAcAHMAUgBHAEIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFhZWiAAAAAAAABvogAAOPUAAAOQWFlaIAAAAAAAAGKZAAC3hQAAGNpYWVogAAAAAAAAJKAAAA+EAAC2z3BhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABYWVogAAAAAAAA9tYAAQAAAADTLW1sdWMAAAAAAAAAAQAAAAxlblVTAAAAIAAAABwARwBvAG8AZwBsAGUAIABJAG4AYwAuACAAMgAwADEANv/bAEMAAwICAgICAwICAgMDAwMEBgQEBAQECAYGBQYJCAoKCQgJCQoMDwwKCw4LCQkNEQ0ODxAQERAKDBITEhATDxAQEP/bAEMBAwMDBAMECAQECBALCQsQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEP/AABEIAAEAAQMBIgACEQEDEQH/xAAVAAEBAAAAAAAAAAAAAAAAAAAACf/EABQQAQAAAAAAAAAAAAAAAAAAAAD/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8AqmAD/9k=";
const C_MES_EXTENSO =   ['Janeiro',
						 'Fevereiro',
						 'Março',
						 'Abril',
						 'Maio',
						 'Junho',
						 'Julho',
						 'Agosto',
						 'Setembro',
						 'Outubro',
						 'Novembro',
						 'Dezembro'
						]

export { 	C_SENHA_SGI,
			menuItems,
			arrayEstadoCivil,
			arrayRegimeBens,
			arraySexo,
			arrayAtivoInativo,
			arraySIM_NAO,
			arrayEnderecoTipo,
			arrayPaises,
			arrayUF,
			options_vuetifymoney,
			
			COR_PRINCIPAL,
			COR_SECUNDARIA,
			COR_SECUNDARIA_2,
			COR_BACKGROUND,
			COR_SUBTITULO,
			COR_PLACEHOLDER,
			COR_BACKGROUND_TEXT,
			COR_SOMBRA,
			COR_BORDA,
			COR_BACKGROUND_2,
			COR_ACAO,

			IMG_BRANCO,
			MASK_CPFCNPJ,
			MASK_TELEFONE,
			MASK_DATA,
			MASK_CEP,
			C_MES_EXTENSO,

			PATH_EDITOR_DOCUMENTOS,
			PATH_EDITOR_DOCUMENTOS_THUMB

		};
